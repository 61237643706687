import React from "react"

import Layout from "../../components/layout"
import Image from "../../components/image"
import Seo from "../../components/seo"
import ButtonDownload from  "../../components/buttonDownload"


const ApplicationPage = () => (
  <Layout>
    <Seo title="L'application gratuite pour les infirmiers libéraux" />
    <div className='line blue-start applications'>
      <h1>TESTEZ GRATUITEMENT</h1>
      <img src="/images/background.svg" className="background" alt="background logo" />
      <div className="whiteBlock">
        <p>
        Pour installer l’application gratuitement sur iOS (Apple)
        ou rdv sur l’Apple store de votre téléphone
        </p>
        <ButtonDownload mobile="iOS" className="no-image" text="Télécharger pour iOS" />    
        <ButtonDownload mobile="iOS" text="Télécharger" />    
      </div>
      <div className="whiteBlock">
        <p>
        Pour installer l’application sur androïd (google samsung, etc)
        ou rdv sur le play store de votre téléphone
        </p>
        <ButtonDownload mobile="Android" className="no-image" text="Télécharger pour Android"  />    
        <ButtonDownload mobile="Android" text="Télécharger"  />    
      </div>
    </div>
    <div className='line'>
      <img src="/images/background.svg" className="background" alt="background logo" />
      <Image src="captures.png" className='image' width="400" />    
    </div>
  </Layout>
)

export default ApplicationPage;
